export default function halsausschnittrechner() {
  // Angabe in cm
  const kopfumfang = 56;
  const halsweite = 38;
  const halsausschnittHoehe = 9;
  function sides(kopfumfang, halsweite, halsausschnittHoehe) {
    const umfangHalsausschnitt = (kopfumfang + halsweite) / 2;

    // halbe Seite berechnet
    const oberkanteZwischensumme =
      (umfangHalsausschnitt / 2 - halsausschnittHoehe) / 2; // Halssausschnitt RT
    const unterkanteZwischensumme = oberkanteZwischensumme / 2; // Halssausschnitt VT (1/4)
    const abnahmen = unterkanteZwischensumme * 1.232; // jede Runde 1 Masche abnehmen
    const schulterZwischensumme =
      umfangHalsausschnitt / 2 -
      (oberkanteZwischensumme + unterkanteZwischensumme + abnahmen); // gerade hochstricken
    const verteilenSchulterhoehe =
      (unterkanteZwischensumme + schulterZwischensumme - halsausschnittHoehe) /
      3.232;
    const schulterhoehe = runden(halsausschnittHoehe - unterkanteZwischensumme);
    const oberkante = runden(
      (oberkanteZwischensumme + verteilenSchulterhoehe * 2) * 2
    );
    const unterkante = runden(
      (unterkanteZwischensumme + verteilenSchulterhoehe) * 2
    );

    return {
      oberkante: [oberkante + " cm", m(oberkante).m + " M"],
      unterkante: [unterkante + " cm", m(unterkante).m + " M"],
      abnahmen: abnahmen + verteilenSchulterhoehe * 0.232,
      schulterhoehe: [schulterhoehe + " cm", m(schulterhoehe).r + " R"],
      umfangHalsausschnitt,
    };
  }

  const calc = sides(kopfumfang, halsweite, halsausschnittHoehe);

  //   console.log(calc);
}

function runden(zahl) {
  return Math.floor(zahl * 100) / 100;
}

function m(zahl) {
  const maschen = 18;
  const reihen = 25;
  return {
    r: runden((reihen * zahl) / 10),
    m: runden((maschen * zahl) / 10),
  };
}
